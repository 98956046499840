import React from "react";
import { LabelText } from "@src/components/LabelText";
import Translate from "@src/components/translation/Translate";
import { useGetSessionIdSync } from "@src/utils/logClient";
import { QRCodeSVG } from "qrcode.react";
import styled from "@emotion/styled";
import { mediaQueriesMax } from "@src/styles/theme";
const StickyQRCode = () => {
  const loggingClientSessionId = useGetSessionIdSync();

  const QRCodeContainer = styled("div")`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: white;
    display: inline-flex;
    padding: 18px;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    border: 2px solid #e2e4f0;
    width: 185px;
    border-radius: 18px;
    text-align: center;
    ${mediaQueriesMax[2]} {
      display: none;
    }
  `;
  return (
    <QRCodeContainer>
      <LabelText>
        <Translate path="components.download_app.title" />
      </LabelText>

      <div style={{ position: "relative", marginTop: "18px", textAlign: "center" }}>
        <div
          style={{
            position: "absolute",
            right: "-50px",
            top: "-45px",
            backgroundImage: "url('https://static.classdojo.com/img/page_homepage/sketch-lines.svg')",
            backgroundSize: "cover",
            width: "72px",
            height: "72px",
          }}
        ></div>
        <QRCodeSVG
          value={`https://classdojo.com/ul/home?appDownload=1&sessionId=${loggingClientSessionId}`}
          size={102}
        />
      </div>
    </QRCodeContainer>
  );
};

export default StickyQRCode;
